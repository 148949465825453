<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>My Subjects</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active">My Subjects</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <form @submit.prevent="submitSearchForm()" class="form-inline">
                   

                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Year/Term</label>
                    <select
                      class="form-control"
                      v-model="search_fields.term_id"
                    >
                      <option value="" selected>All</option>
                      <option
                        v-for="term in lists.terms"
                        :key="term.id"
                        :value="term.id"
                      >
                      {{ term.year.value }}: {{ term.name }}
                      </option>
                    </select>
                  </div>
                  
                  <div class="form-group mb-2 mr-2">
                    <button
                      name="search"
                      type="submit"
                      class="btn btn-primary"
                      value="Filter"
                      onclick="this.blur();"
                    >
                      Filter
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="table-responsive">
              <table
                class="table table-bordered table-striped verticle-middle table-responsive-sm"
              >
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Academic Year</th>
                    <th scope="col">Term</th>
                    <th scope="col">Level</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="subjects.length">
                  <tr v-for="subject in subjects" :key="subject.id">
                    <td>{{ subject.id }}</td>
                    <td>{{ subject.name }}</td>
                    <td>{{ subject.year.value }}</td>
                    <td>{{ subject.term.name }}</td>
                    <td>{{ subject.level.value }}</td>
                    <td>
                      <span>
                        <router-link
                          v-can="'view_subjects'"
                          :to="{
                            name: 'Parent-Subjects-Single',
                            params: { id: subject.id },
                          }"
                          title="View"
                          class="mr-4"
                          ><i class="fa fa-eye color-muted"></i>
                        </router-link>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="6">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useSubject from "@/composables/subjects";
import { onBeforeMount, reactive } from "vue";
export default {
  props: {
    student_id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const search_fields = reactive({
      student_id: props.student_id,
      name: "",
      term_id: "",
    });

    const { getSubjects, subjects, lists } = useSubject();

    onBeforeMount(async () => {
      await getSubjects(null, search_fields);
    });

    const submitSearchForm = async () => {
      console.log(search_fields);
      await getSubjects(null, search_fields);
    };

    return {
      lists,
      subjects,
      search_fields,
      submitSearchForm,
    };
  },
};
</script>
